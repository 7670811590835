<template>
    <el-drawer
            :close-on-press-escape="false"
            :wrapperClosable="false"
            @close="dialogClose"
            :show-close="false"
            :custom-class="'doc-drawer'"
            size="800px"
            :visible.sync="dialogVisible">
        <div class="drawer-title text-right" slot="title">
            <feather-icon
                    v-if="form.id && collectPerm"
                    @click="collectDocument"
                    icon="StarIcon"
                    size="16"
                    :style="form.have_collect ? {color: '#ff9f43!important'} : {} "
                    class="align-text-top ml-1"
            />
            <feather-icon
                    @click="delDocument"
                    v-if="form.id && delDocPerm"
                    icon="Trash2Icon"
                    size="16"
                    class="align-text-top ml-1"
            />
            <feather-icon
                    icon="XIcon"
                    size="16"
                    @click="dialogClose"
                    class="align-text-top ml-1"
            />
        </div>
        <div class="drawer-body">
            <el-form
                    v-if="dialogVisible"
                    ref="form"
                    v-loading="loading"
                    :model="form"
                    label-width="120px"
            >
                <el-form-item
                        label="阶段活动"
                        prop="stage"
                        :rules="[ { required: true, message: '阶段活动不能为空', trigger: 'change' }]"
                >
                    <el-select v-model="form.stage"  placeholder="请选择阶段活动">
                        <el-option v-for="(item,index) in stageOption" :label="item.name" :value="item.value" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                        label="交付文件名称"
                        prop="documentName"
                        :rules="[ { required: true, message: '阶段活动不能为空', trigger: 'change' }]"
                >
                    <el-input

                            v-model="form.documentName"
                            maxlength="20"
                    />
                </el-form-item>
                <el-form-item
                        label="历史版本"
                        v-if="form.id"
                >
                    <el-select @change="histroyFileChange" v-model="histroyFile"  placeholder="请选择历史版本">
                        <el-option v-for="(item,index) in fileList" :key="index" :value="item.id" :label="item.file_name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="上传文件" prop="fileName" :rules="form.id ? [] : [ { required: true, message: '名称不能为空', trigger: 'change' }]">
                    <input type="file" id="uploadFileInput" style="display: none" @change="uploadFileInputChange">
                    <el-input disabled v-model="form.fileName">
                        <template slot="append">
                            <div style="cursor: pointer;" @click="uploadFileClick">选择文件</div>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="标签">
                    <el-select v-model="form.tags" multiple placeholder="请选择标签">
                        <el-option v-for="(item,index) in tags" :key="index" :value="item.tag_name" :label="item.tag_name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="文档描述">
                    <div  class="desc-editor">
                        <div id="quill-toolbar" class="d-flex border-0">
                            <!-- Add a bold button -->
                            <button class="ql-bold" />
                            <button class="ql-italic" />
                            <button class="ql-underline" />
                            <button class="ql-align" />
                            <button class="ql-link" />
                            <button class="ql-image" />
                        </div>
                        <quill-editor :style="{height:pageH+'px'}" ref="theQuillEditor" v-model="form.description" :options="editorOption"/>
                    </div>
                </el-form-item>
            </el-form>
            <div
                    slot="footer"
                    class="dialog-footer text-center"
            >
                <el-button @click="dialogVisible = false">
                    取 消
                </el-button>
                <el-button
                        v-if="saveDocPerm"
                        type="primary"
                        :disabled="loading"
                        @click="submitForm"
                >
                    提 交
                </el-button>
            </div>
        </div>

    </el-drawer>
</template>

<script>
  import "quill/dist/quill.core.css";
  // eslint-disable-next-line
  import "quill/dist/quill.snow.css";
  // eslint-disable-next-line
  import "quill/dist/quill.bubble.css";
  import { collectChange, listTag, saveOrUpdateDocument, DelDocument, DelTag } from '@/api/docment/docment'
  import { error, findBtnPerm, success } from '@core/utils/utils'
  import { quillEditor } from "vue-quill-editor";
  // 工具栏
  // const toolbarOptions = [
  //   ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
  //   [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
  //   [{ align: [] }], // 对齐方式-----[{ align: [] }]
  //   [{ size: fontSizeStyle.whitelist }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
  //   [{ font: fonts }], // 字体种类-----[{ font: [] }]
  //   [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
  //   [{ direction: 'ltl' }], // 文本方向-----[{'direction': 'rtl'}]
  //   [{ direction: 'rtl' }], // 文本方向-----[{'direction': 'rtl'}]
  //   [{ indent: '-1' }, { indent: '+1' }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
  //   [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
  //   [{ script: 'sub' }, { script: 'super' }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
  //   ['blockquote', 'code-block'], // 引用  代码块-----['blockquote', 'code-block']
  //   ['clean'], // 清除文本格式-----['clean']
  //   ['link', 'image', 'video'] // 链接、图片、视频-----['link', 'image', 'video']
  // ]
  export default {
    name: 'DocSave',
    components: { quillEditor },
    data(){
      return {
        delDocPerm: findBtnPerm('doc-7'),
        collectPerm: findBtnPerm('doc-8'),
        removeCollectPerm: findBtnPerm('152'),
        saveDocPerm: findBtnPerm('doc-4'),
        histroyFile: '',
        fileList: [],
        pageH: document.body.clientHeight - 420,
        stageOption:[
          {name:'咨询服务阶段',value:1},
          {name:'咨询规划阶段',value:2},
          {name:'管理体系建设阶段',value:3},
          {name:'数据资产发现阶段',value:4},
        ],
        dialogVisible: false,
        loading: false,
        form: {
          documentName: '',
          description: '',
          stage: '',
          fileName: '',
          tags: [],
          have_collect: false,
          id: ''
        },
        tags: [],
        editorOption: {
          modules: {
            toolbar: "#quill-toolbar",
          },
          placeholder: "请输入描述",
        },
      }
    },
   methods: {
     histroyFileChange(){
       this.fileList.forEach(f=>{
         if(f.id == this.histroyFile){
           this.form.fileName = f.file_name
         }
       })
     },
     collectDocument(){
       const msg = this.form.have_collect ? '确认取消收藏吗?' : '确认收藏吗?'
       const type = this.form.have_collect ? 2 : 1
       this.$confirm(msg, '提示', {
         confirmButtonText: '确定',
         cancelButtonText: '取消',
         type: 'warning',
       }).then(() => {
         this.loading = true
         collectChange(type,this.form.id).then(res=>{
           this.loading = false
           const resData = res.data
           if (resData.code === 0) {
             this.form.have_collect = !this.form.have_collect
             success(resData.msg)
             this.$emit('handleFilter')
           } else {
             error(resData.msg)
           }
         }).catch(e=>{
           error('服务错误')
           this.loading = false
         })
       })
     },
     delDocument() {
       this.$confirm('是否确认删除吗?', '提示', {
         confirmButtonText: '确定',
         cancelButtonText: '取消',
         type: 'warning',
         closeOnClickModal: false,
       }).then(() => {
         this.loading = true
         DelDocument({ id:this.form.id }).then(res => {
           this.loading = false
           const resData = res.data
           // eslint-disable-next-line eqeqeq
           if (resData.code === 0) {
             success(resData.msg)
             this.$emit('handleFilter')
             this.dialogVisible = false
           } else {
             error(resData.msg)
           }
         }).catch(e=>{
           error('服务错误')
           this.loading = false
         })
       })
     },
     uploadFileClick(){
       document.getElementById('uploadFileInput').click()
     },
     uploadFileInputChange(){
       const uploadDom = document.getElementById('uploadFileInput')
       const fileVal = uploadDom.files
       this.form.fileName = fileVal[0].name
     },
     dialogClose() {
       this.form = {
         documentName: '',
         description: '',
         stage: '',
         fileName: '',
         tags: [],
         have_collect: false,
         id: ''
       }
       this.$refs.form.resetFields()
       this.dialogVisible = false
     },
     submitForm() {
       this.$refs.form.validate(valid => {
         if (valid) {
           const saveType = this.form.id ? 2 : 1
           this.loading = true
           const uploadDom = document.getElementById('uploadFileInput')
           const formData = new FormData()
           formData.append('file', uploadDom.files[0])
           formData.append('documentName', this.form.documentName)
           formData.append('description', this.form.description)
           formData.append('stage', this.form.stage)
           formData.append('tags', this.form.tags)
           formData.append('level', 1)
           if(this.form.id){
             formData.append('id', this.form.id)
           }
           saveOrUpdateDocument(saveType,formData).then(res => {
             this.loading = false
             const resData = res.data
             if (resData.code === 0) {
               success(resData.msg)
               this.dialogVisible = false
               this.$emit('handleFilter')
             } else {
               error(resData.msg)
             }
           })
         } else {
           return false
         }
       })
     },
   }
  }
</script>

<style>
    .desc-editor{
        border: 1px solid #ccc;
        border-bottom: none;
    }
   .doc-drawer .el-drawer__header{
        padding: 0 20px;
    }
    .drawer-title{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 40px;
        .feather{
            cursor: pointer;
        }
    }
    .drawer-body{
        padding-right: 20px;
    }
    .ql-container.ql-snow{
        border-left: none;
        border-right: none;
    }
</style>
