<template>
    <el-card>
        <div class="flex-start match-height doc-wrap">
            <div class="doc-left">
                <div v-if="saveDocPerm" class="add-doc">
                    <el-button @click="docSaveShow(1)" class="btn-block text-center" size="small" type="primary">新建文档</el-button>
                </div>
                <VuePerfectScrollbar v-loading="tagLoading" :style="{height: pageH+'px'}"  :settings="settings">
                    <div  :class="docActive == index ? 'doc-active' : ''" @click="docTypeClick(1,index,item)" class="doc-item" v-for="(item,index) in docTypeList" :key="index">
                        <i class="iconfont icon-w_wendang"></i>
                        <span>{{item.name}}</span>
                    </div>
                    <div class="flex-between tag-title">
                        <span>标签</span>
                        <i v-if="saveTagPerm" @click="tagSaveShow" class="el-icon-plus" style="cursor: pointer"></i>
                    </div>
                    <div   :class="docActive == (index+4) ? 'doc-active' : ''" @click="docTypeClick(2,index,item)" class="doc-item flex-between" v-for="(item,index) in tags" :key="index+'a'">
                        <div>
                            <span class="bullet bullet-sm mr-1" :class="item.color"></span>
                            <span>{{item.tag_name}}</span>
                        </div>
                        <feather-icon
                                v-if="delTagPerm"
                                @click.stop="delTag(item.id)"
                                icon="XIcon"
                                size="16"
                                class="align-text-top ml-1"
                        />
                    </div>
                </VuePerfectScrollbar>

            </div>
            <div class="doc-right">
                <div class="doc-search-wrap flex-between">
                    <div class="doc-search-left flex-start">
                        <i style="cursor: pointer" @click.stop="handleFilter" class="el-icon-search"></i>
                        <el-input placeholder="请输入文档名称" @blur.stop="handleFilter" v-model="listQuery.SearchStr" />
                    </div>
                    <b-dropdown class="dropdown-icon-wrapper doc-dropdown">
                        <template #button-content>
                            <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    color="'red'"
                                    class="align-middle"
                            />
                        </template>
<!--                        <b-dropdown-item>-->
<!--                            <div>全部下载</div>-->
<!--                        </b-dropdown-item>-->
                        <b-dropdown-item v-if="downloadPerm">
                            <div @click="downloadChecked">下载选中</div>
                        </b-dropdown-item>
                        <b-dropdown-item>
                            <div @click="removeChecked">取消选中</div>
                        </b-dropdown-item>
                        <b-dropdown-item>
                            <div @click="showCollectFilter">
                                {{showCollect ? '隐藏收藏' : '显示收藏'}}
                            </div>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <VuePerfectScrollbar v-loading="loading" :style="{height: pageH+'px'}" :settings="settings" ref="taskListPS">
                    <transition-group v-show="docList.length>0" class="todo-list" name="list-enter-up" tag="ul" appear>
                        <li @click="docSaveShow(2,doc)"  class="doc-li" v-for="(doc, index) in docList" :key="index+'b'">
                            <div>
                                <feather-icon
                                        v-if="updateDocPerm"
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-text-top"
                                />
                                <feather-icon
                                        v-if="doc.have_collect"
                                        icon="StarIcon"
                                        size="16"
                                        style="color:#ff9f43!important;top:2px;position: relative"
                                        class="align-text-top mr-1"
                                />
                                <span  @click.stop="ac">
                                    <el-checkbox  v-model="doc.checked"><span class="documentName">{{doc.document_name}}</span></el-checkbox>
                                </span>
                            </div>
                            <div  class="badge-wrapper" style="max-width: 80%">
                                <span v-for="(tag,k) in doc.tags" class="badge ml-1 text-capitalize badge-pill" :key="k" :class="tag.color">{{tag.tag_name}}</span>
                            </div>
                        </li>
                    </transition-group>
                    <el-empty description="暂无数据" v-show="docList.length==0 && !loading"></el-empty>
                    <pagination
                            v-show="total>0"
                            :total="total"
                            :page.sync="listQuery.page"
                            :limit.sync="listQuery.pageSize"
                            @pagination="getList"
                    />
                </VuePerfectScrollbar>
            </div>
        </div>
        <TagSave ref="tagSave" @handleFilter="getTags"/>
        <DocSave ref="docSave" @handleFilter="handleFilter"/>
    </el-card>
</template>

<script>
  import { error, findBtnPerm, success, getBtnPerms, verifyBtnPerm } from '@core/utils/utils'
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import TagSave from '@/views/docment/TagSave'
  import DocSave from '@/views/docment/DocSave'
  import { PageDocument,listTag,DelTag,DownloadDocument } from '@/api/docment/docment'
  import pagination from '@core/components/pagination/Pagination'
  export default {
    name: 'docment',
    components: { VuePerfectScrollbar,TagSave,DocSave,pagination },
    data(){
      return {
        saveDocPerm: findBtnPerm('doc-4'),
        updateDocPerm: findBtnPerm('doc-6'),
        delDocPerm: findBtnPerm('doc-7'),
        saveTagPerm: findBtnPerm('doc-2'),
        delTagPerm: findBtnPerm('doc-3'),
        downloadPerm: findBtnPerm('doc-9'),
        collectPerm: findBtnPerm('doc-8'),
        readPerm: findBtnPerm('doc-5'),
        tagLoading: false,
        select: '',
        showCollect: false,
        listQuery:{
          page: 1,
          pageSize: 10,
          SearchStr: '',
          stage: 1,
          show_collect: 0,
          tags: []
        },
        total:0,
        pageH: document.body.clientHeight - 200,
        settings : {
          maxScrollbarLength : 60,
          wheelSpeed: 0.30,
        },
        docTypeList:[
          {name:'咨询服务阶段',value:1},
          {name:'咨询规划阶段',value:2},
          {name:'管理体系建设阶段',value:3},
          {name:'数据资产发现阶段',value:4},
        ],
        docActive:0,
        tags:[],
        docList: [],
        loading:false,
      }
    },
    created() {
      getBtnPerms((btnPerms) => {
        this.saveDocPerm = verifyBtnPerm(btnPerms, 'doc-4')
        this.updateDocPerm = verifyBtnPerm(btnPerms, 'doc-6')
        this.delDocPerm = verifyBtnPerm(btnPerms, 'doc-7')
        this.saveTagPerm = verifyBtnPerm(btnPerms, 'doc-2')
        this.delTagPerm = verifyBtnPerm(btnPerms, 'doc-3')
        this.downloadPerm = verifyBtnPerm(btnPerms, 'doc-9')
        this.collectPerm = verifyBtnPerm(btnPerms, 'doc-8')
        this.readPerm = verifyBtnPerm(btnPerms, 'doc-5')
      })
      this.getList()
      this.getTags()
    },
    methods: {
      ac(){},
      removeChecked(){
        this.docList.forEach(d=>{
          d.checked = false
        })
      },
      downloadChecked(){
        let hasChecked = false
        const checkedIds = []
        this.docList.forEach(d=>{
          if(d.checked){
            hasChecked = true
            checkedIds.push(d.id)
          }
        })
        if(hasChecked){
          DownloadDocument(checkedIds.toString())
        }else {
          error('请选择需下载的文档')
        }
      },
      showCollectFilter(){
        this.showCollect = !this.showCollect
        this.listQuery.show_collect = this.showCollect ? 1 : 0
        this.listQuery.tags = []
        this.handleFilter()
      },
      delTag(id) {
        this.$confirm('是否确认删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false,
        }).then(() => {
          this.tagLoading = true
          DelTag({ id }).then(res => {
            this.tagLoading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.getTags()
              this.handleFilter()
            } else {
              error(resData.msg)
            }
          }).catch(e=>{
            error('服务错误')
            this.tagLoading = false
          })
        })
      },
      docSaveShow(type,item){
        this.$refs.docSave.dialogVisible = true
        this.$refs.docSave.tags = this.tags
        if(type == 2){
          const tagsVal = []
          item.tags.forEach(t=>{
            tagsVal.push(t.tag_name)
          })
          this.$refs.docSave.form.documentName = item.document_name
          this.$refs.docSave.form.description = item.description
          this.$refs.docSave.form.stage = item.stage
          this.$refs.docSave.form.have_collect = item.have_collect
          this.$refs.docSave.form.tags = tagsVal
          this.$refs.docSave.form.id = item.id
          this.$refs.docSave.fileList = item.file_list
          if(item.file_list.length>0){
            this.$refs.docSave.histroyFile = item.file_list[0].id
            this.$refs.docSave.form.fileName = item.file_list[0].file_name
          }
        }
      },
      tagSaveShow(){
        if(this.tags.length >=10){
          error('最多只能添加十个标签')
        }else {
          this.$refs.tagSave.dialogVisible = true
          this.$refs.tagSave.form.sort_no = this.tags.length + 1
          if(this.tags.length === 0) {
            this.$refs.tagSave.form.color = 'bullet-bg-0'
          }else{
            this.$refs.tagSave.form.color = 'bullet-bg-'+this.tags.length
          }
        }

      },
      docTypeClick(type,index,item){
        if(type === 1){
          this.docActive = index
          this.listQuery.stage = item.value
          this.listQuery.tags = []
        }else{
          this.docActive = index + 4
          const tags = []
          tags.push(item.tag_name)
          this.listQuery.tags = tags
          this.listQuery.stage = 0
        }
        this.handleFilter()
      },
      getTags(){
        this.tagLoading = true
        listTag().then(res=>{
          this.tagLoading = false
          const resData = res.data
          if (resData.code === 0) {
            this.tags = resData.data
          }
        }).catch(e=>{
          error('服务错误')
          this.tagLoading = false
        })
      },
      getList() {
        this.loading = true
        PageDocument(this.listQuery).then(res => {
          this.loading = false
          this.docList = []
          if(res.data.data.list){
            res.data.data.list.forEach(r=>{
               r.checked = false
              this.docList.push(r)
            })
          }
          this.total = Number(res.data.data.total)
        }).catch(() => {
          this.loading = false
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
    }
  }
</script>

<style lang="scss">
    .doc-dropdown .btn-secondary,
    .doc-dropdown .btn-secondary:hover{
        border: none !important;
        background-color: transparent !important;
        box-shadow:none !important;
    }
    .feather-more-vertical{
        color: #6e6b7b !important;
    }
    .doc-wrap{
        height: 100%;
        border: 1px solid #ebe9f1;
        align-items: baseline;
        border-radius: 4px;
        .doc-left{
            width: 260px;
            border-right: 1px solid #ebe9f1;
            height: 100%;
            .add-doc{
               padding: 10px;
            }
            .doc-item{
                padding: 10px;
                cursor: pointer;
                i{
                    font-size: 20px;
                    margin-right: 10px;
                }
            }
            .doc-item.doc-active{
                border-left: 2px solid #0d40b2;
                color: #0d40b2;
                position: relative;
            }
            .tag-title{
                padding:10px;
                border-top: 1px solid #ebe9f1;
                border-bottom: 1px solid #ebe9f1;
                align-items: center;
                /*color: #b9b9c3;*/
            }
        }
        .doc-right{
            width: calc(100% - 260px);
            height: 100%;
            .doc-search-wrap{
                width: 100%;
                padding: 0 20px;
                border-bottom: 1px solid #ebe9f1;
                height: 40px;
                .doc-search-left{
                    width: 80%;
                    .el-input__inner{
                        border: none !important;
                    }
                }
                .el-dropdown{
                    display: flex;
                    align-items: center;
                }
            }

           .doc-li{
               /*transition: all .2s;*/
               cursor: pointer;
               padding:20px 10px;
               border-bottom: 1px solid #ebe9f1;
               color: #6e6b7b;
               display: flex;
               justify-content: space-between;
               align-items: center;
               .feather-more-vertical{
                   margin-right: 10px;
                   position: relative;
                   top: 2px;
                   opacity: 0;
               }
               .documentName{
                   /*margin-left: 10px;*/
                   font-size: 12px;
                   color: #6e6b7b;
               }
           }
            .doc-li:hover {
                transform: translateY(-4px);
                box-shadow: 0 3px 10px 0 #ebe9f1;
                .feather-more-vertical{
                    opacity: 1;
                }
            }
        }
    }
    .bullet-bg-0{background: #7367f0}
    .bullet-bg-1{background: #28c76f}
    .bullet-bg-2{background: #ff9f43}
    .bullet-bg-3{background: #ea5455}
    .bullet-bg-4{background: #00cfe8}
    .bullet-bg-5{background: #4839eb}
    .bullet-bg-6{background: #4839eb}
    .bullet-bg-7{background: #1f9d57}
    .bullet-bg-8{background: #ff8510}
    .bullet-bg-9{background: #48da89}

</style>
