<template>
    <el-dialog
            :visible.sync="dialogVisible"
            width="620px"
            @close="dialogClose"
    >
        <div slot="title">
            <span style="font-weight: 700;font-size: 18px">添加标签</span>
        </div>
        <el-form
                v-if="dialogVisible"
                ref="form"
                v-loading="loading"
                :model="form"
                label-width="120px"
        >
            <el-form-item
                    label="标签名称"
                    prop="tag_name"
                    :rules="[ { required: true, message: '名称不能为空', trigger: 'change' }]"
            >
                <el-input

                        v-model="form.tag_name"
                        maxlength="20"
                />
            </el-form-item>
        </el-form>
        <div
                slot="footer"
                class="dialog-footer"
        >
            <el-button @click="dialogVisible = false">
                取 消
            </el-button>
            <el-button
                    type="primary"
                    :disabled="loading"
                    @click="submitForm"
            >
                提 交
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
  import { error, success } from '@core/utils/utils'
  import { CreateTag } from '@/api/docment/docment'

  export default {
    name: 'TagSave',
    data(){
      return {
        loading: false,
        dialogVisible: false,
        form: {
          tag_name: '',
          type: 1,
          sort_no: 0,
          level: 1,
          color:''
        },
      }
    },
    methods: {
      dialogClose() {
        this.form = {
          tag_name: ''
        }
        this.$refs.form.resetFields()
      },
      submitForm() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.loading = true
            CreateTag(this.form).then(res => {
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.dialogVisible = false
                this.$emit('handleFilter')
              } else {
                error(resData.msg)
              }
            })
          } else {
            return false
          }
        })
      },
    }
  }
</script>

<style scoped>

</style>
