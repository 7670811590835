import {  get, post,downloadByGet } from '@/libs/axios'

// 查询cookie列表
export const PageDocument = params => post('/enterprise/document/PageDocument', params)
export const CreateTag = params => post('/enterprise/document/CreateTag', params)
export const saveOrUpdateDocument = (type,params) => {
  const submitUrl = type === 1 ? '/enterprise/document/CreateDocument' : '/enterprise/document/UpdateDocument'
  return post(submitUrl, params)
}
export const DelTag = params => post('/enterprise/document/DelTag', params)
export const DelDocument = params => post('/enterprise/document/DelDocument', params)
export const listTag = () => get('/enterprise/document/listTag')
export const collectChange = (type,id) =>{
  const submitUrl = type === 1 ? `/enterprise/document/CollectDocument?id=${id}` : `/enterprise/document/UnCollectDocument?id=${id}`
  return get(submitUrl)
}
export const DownloadDocument = ids => downloadByGet('/enterprise/document/DownloadDocument',{ids})

